<template>
  <n-image-group>
    <n-space>
      <n-image width="100" :src="img1" />
      <n-image width="100" src="https://gw.alipayobjects.com/zos/antfincdn/aPkFc8Sj7n/method-draw-image.svg" />
      <img src="./../assets/e49c3e73-ea92-421c-b071-b3f7cea59765.png">
      <img src="./../assets/20230213231001.jpg">

      <img src="./../assets/20230517212000.png">


      
    </n-space>
  </n-image-group>
</template>

<script>

// import { NButton } from "naive-ui";
// import { NAvatar } from 'naive-ui';

import { NImageGroup, NImage, NSpace } from "naive-ui";


export default {
  components: {
    //   NButton
    // NAvatar,
    // NButton,
    NImageGroup,
    NImage,
    NSpace,
  },
  data() {
    return {

    };
  },
  mounted() {

  },
  methods: {

  },
};
</script>